"use client";

/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { createTRPCProxyClient, httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
// import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";
import type { AppRouter } from "~/server/api/root";

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [403, 404];

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3003}`; // dev SSR should use localhost
};

const trpcOption = {
  /**
   * Transformer used for data de-serialization from the server.
   *
   * @see https://trpc.io/docs/data-transformers
   */
  // transformer: superjson,

  /**
   * Links used to determine request flow from client to server.
   *
   * @see https://trpc.io/docs/links
   */
  links: [
    loggerLink({
      enabled: (opts) =>
        process.env.NODE_ENV === "development" ||
        (opts.direction === "down" && opts.result instanceof Error),
    }),
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
      transformer: superjson,
    }),
  ],
};

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      ...trpcOption,
      queryClientConfig: {
        defaultOptions: {
          mutations: {
            throwOnError: true,
            useErrorBoundary: true,
          },
          queries: {
            throwOnError: true,
            useErrorBoundary: true,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
              // @ts-ignore
              const status = error?.data?.httpStatus ?? error?.status;
              console.log(`muly:api:retry [${status}]`, {
                error,
                failureCount,
              });
              if (failureCount > MAX_RETRIES) {
                return false;
              }

              if (HTTP_STATUS_TO_NOT_RETRY.includes(status)) {
                console.log(`Aborting retry due to ${status} status`);
                return false;
              }

              return true;
            },
          },
        },
      },
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
  transformer: superjson,
});

// /**
//  * Inference helper for inputs.
//  *
//  * @example type HelloInput = RouterInputs['example']['hello']
//  */
// export type RouterInputs = inferRouterInputs<AppRouter>;
//
// /**
//  * Inference helper for outputs.
//  *
//  * @example type HelloOutput = RouterOutputs['example']['hello']
//  */
// export type RouterOutputs = inferRouterOutputs<AppRouter>;

// export const trpcVanillaClient = createTRPCProxyClient<AppRouter>(trpcOption);

export const trpcVanillaClient = createTRPCProxyClient<AppRouter>(trpcOption);
