import { ChevronRight } from "lucide-react";

interface Props {
  part1: string;
  part2: string;
}

export const Breadcrumbs = ({ part1, part2 }: Props) => {
  return (
    <div className="flex flex-row items-center text-sm text-black2">
      <p className="whitespace-nowrap">{part1}</p>
      <div className="mx-2 flex items-center">
        <ChevronRight className="size-4 opacity-70" />
      </div>
      <p className="whitespace-nowrap">{part2}</p>
    </div>
  );
};
