import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "~/lib/utils";

const buttonVariants = cva(
  "inline-flex [&>svg]:inline-block items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blueblack disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-blueblack text-grey4 shadow hover:bg-blueblack/90",
        primary: "bg-brand text-grey4 shadow hover:bg-brand/90 rounded-full",
        destructive:
          "bg-critical text-white shadow-sm hover:bg-critical/90 min-w-60",
        success: "bg-good text-white shadow-sm hover:bg-good/90 min-w-60",
        outline:
          "border border-info bg-transparent shadow-sm hover:bg-grey4 hover:text-blueblack",
        secondary: "bg-grey3 text-blueblack shadow-sm hover:bg-grey3/80",
        ghost: "hover:bg-grey4 hover:text-blueblack",
        ghostDarken:
          "bg-transparent hover:bg-grey3 hover:text-blueblack rounded-full p-0",
        link: "text-blueblack underline-offset-4 underline hover:underline",
        text: "text-sm font-medium text-grey1 min-w-fit text-nowrap flex gap-2",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-sm",
        lg: "h-10 rounded-md px-8",
        icon: "size-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
