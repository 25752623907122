import type { Config } from "tailwindcss";
import tailwindcssAnimate from "tailwindcss-animate";

export default {
  content: ["./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    colors: ({ colors }) => ({
      current: colors.current,
      transparent: colors.transparent,
      white: colors.white,
      black: colors.black,

      brand: "var(--brand)",
      brandsecondary: "var(--brandsecondary)",
      good: "var(--good)",
      acceptable: "var(--acceptable)",
      critical: "var(--critical)",
      info: "var(--info)",
      black1: "var(--black1)",
      black2: "var(--black2)",
      blueblack: "var(--blueblack)",
      blueblack2: "var(--blueblack2)",
      grey1: "var(--grey1)",
      grey2: "var(--grey2)",
      grey3: "var(--grey3)",
      grey4: "var(--grey4)",
    }),
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    fontFamily: {
      sans: ["var(--font-inter)"],
    },
    fontSize: {
      extrasmall: ["0.375rem", { lineHeight: "" }], // 6px | auto
      sm: ["0.875rem", { lineHeight: "1.25rem" }], // 14px | 20px
      hsm: ["1.125rem", { lineHeight: "1.75rem" }], // 18px | 28px
      hlg: ["1.75rem", { lineHeight: "2.375rem" }], // 28px | 38px
    },
    fontWeight: {
      normal: "400",
      medium: "500",
    },
    extend: {
      borderWidth: {
        6: "6px",
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
      },
      zIndex: {
        "1": "1",
      },
      width: {
        "webkit-fill-available": "-webkit-fill-available",
      },
      height: {
        "500": "500px", // Custom height
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
  },
  plugins: [tailwindcssAnimate],
} satisfies Config;
