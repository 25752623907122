export const ReportsIconSidebar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 17.5V14.5M11.5 17.5V8.5M16.5 17.5V13.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M21.5 5.5C21.5 7.15685 20.1569 8.5 18.5 8.5C16.8431 8.5 15.5 7.15685 15.5 5.5C15.5 3.84315 16.8431 2.5 18.5 2.5C20.1569 2.5 21.5 3.84315 21.5 5.5Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M21.4955 11C21.4955 11 21.5 11.3395 21.5 12C21.5 16.4784 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4784 2.5 12C2.5 7.52169 2.5 5.28252 3.89124 3.89127C5.28249 2.50003 7.52166 2.50003 12 2.50003L13 2.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
interface LogoIconProps {
  className?: string;
  fill?: boolean;
  opacity?: boolean;
}
export const LogoIcon = ({
  className = "",
  fill = false,
  opacity = false,
}: LogoIconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {fill && (
        <path
          opacity="0.4"
          d="M10.5698 10.5698C4.66136 16.4783 2.3028 23.6992 5.30182 26.6981C6.9243 28.3207 9.78247 28.3751 13 27.1476C15.7297 26.1063 18.7183 24.1421 21.4301 21.4301C27.3387 15.5217 29.6972 8.30083 26.6981 5.30182C25.1773 3.78092 22.5707 3.6379 19.6 4.63696C16.1687 5.79096 13.1142 8.02543 10.5698 10.5698Z"
          fill="white"
        />
      )}
      \
      <path
        d="M27.5507 11.8C28.3363 9.08029 28.1157 6.7194 26.6981 5.30181C23.6992 2.3028 16.4783 4.66136 10.5698 10.5698C9.93863 11.201 9.34796 11.8471 8.8 12.5015M23.2 19.4984C22.652 20.1528 22.0613 20.7991 21.4301 21.4301C15.5217 27.3387 8.30083 29.6972 5.30182 26.6981C3.78092 25.1773 3.6379 22.5707 4.63696 19.6"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      \
      <path
        d="M13 23.6079C12.1753 22.9485 11.3608 22.2212 10.5698 21.4301C4.66136 15.5217 2.3028 8.30083 5.30182 5.30182C6.8227 3.78092 9.42936 3.6379 12.4 4.63696M19 27.1476C22.2175 28.3751 25.0757 28.3207 26.6981 26.6981C29.6972 23.6992 27.3387 16.4783 21.4301 10.5698C20.6392 9.77884 19.8248 9.05149 19 8.39209"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      \
      <path
        d="M16.9031 12.4004L14.5683 15.1609C14.2832 15.5305 14.1408 15.7153 14.2291 15.8578C14.3174 16.0003 14.5743 16.0003 15.088 16.0003H16.9182C17.4319 16.0003 17.6888 16.0003 17.7771 16.143C17.8654 16.2855 17.7228 16.4703 17.4379 16.8399L15.088 19.6003"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
