import * as Sentry from "@sentry/react";
import type { Session } from "@supabase/auth-helpers-nextjs";
import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { TriggerProvider } from "@trigger.dev/react";
import type { NextComponentType, NextPageContext } from "next";
import type { AppProps } from "next/app";
import { Inter } from "next/font/google";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ConfigProvider } from "~/components/common/config/config-context";
import { ErrorBoundaryComponent } from "~/components/common/ui/ErrorBoundaryComponent";
import { Layout } from "~/components/layout/Layout";
import { TooltipProvider } from "~/components/ui/tooltip";
import { env } from "~/env.mjs";
import "~/styles/globals.css";
import { api } from "~/utils/api";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
  weight: ["400", "500"],
  display: "swap",
});
const fontClasses = `${inter.variable} font-sans antialiased`;

type MyAppProps = AppProps<{ session: Session | null }> & {
  Component: NextComponentType<NextPageContext, any, any> & {
    disableLayout?: boolean;
    disableNeedUser?: boolean;
    disableNeedHotelData?: boolean;
  };
};

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  const router = useRouter();
  const supabase = createPagesBrowserClient();
  const [supabaseSession, setSupabaseSession] = useState<
    Session | null | undefined
  >(undefined);
  const [authEventsReceived, setAuthEventsReceived] = useState(false);

  const pageNeedUser = Component.disableNeedUser !== true;
  const pageNeedHotelData = Component.disableNeedHotelData !== true;
  const noLayout = !!Component.disableLayout;

  useEffect(() => {
    const authListener = supabase.auth.onAuthStateChange(
      (event, sessionClient) => {
        if (supabaseSession?.user?.id !== sessionClient?.user?.id) {
          console.log(`muly:_app:onAuthStateChange EVENT=${event}`, {
            event,
            sessionClient,
            id: supabaseSession?.user?.id,
            idNew: sessionClient?.user?.id,
          });

          setSupabaseSession(sessionClient);
        }
        setAuthEventsReceived(true);
      },
    );

    // Clean up the listener when the component unmounts
    return () => {
      authListener.data.subscription.unsubscribe();
    };
    // be careful what we add Here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSupabaseSession, supabaseSession?.user?.id]);

  useEffect(() => {
    document.body.className += fontClasses;
  }, []);

  return (
    <main className={fontClasses}>
      <Sentry.ErrorBoundary
        // eslint-disable-next-line @typescript-eslint/unbound-method
        fallback={({ error, componentStack, resetError }) => {
          console.error(`muly:MyApp:Sentry.ErrorBoundary`, {
            error,
            componentStack,
            resetError,
          });

          return (
            <ErrorBoundaryComponent
              onReload={() => {
                resetError();
                router.reload();
              }}
              error={error as Error}
            />
          );
        }}
      >
        <TriggerProvider publicApiKey={env.NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY}>
          <ConfigProvider
            supabaseSession={supabaseSession}
            authEventsReceived={authEventsReceived}
            pageNeedUser={pageNeedUser}
            pageNeedHotelData={pageNeedHotelData}
          >
            <TooltipProvider>
              {!!noLayout ? (
                <Component {...pageProps} />
              ) : (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              )}
            </TooltipProvider>
          </ConfigProvider>
        </TriggerProvider>
      </Sentry.ErrorBoundary>
    </main>
  );
};

export default api.withTRPC(MyApp);
