import { ChevronRight, LayoutDashboard } from "lucide-react";
import { SelectHotelHeader } from "~/components/hotels/select-hotel-header";
import { Breadcrumbs } from "~/components/layout/Breadcrumbs";
import { cn } from "~/lib/utils";
import type { UserProfile } from "~/server/db-types";
import { Button } from "../common/ui/button";

interface HeaderProps {
  hotelId: string;
  hotelName: string;
  pageDisplayName: string;
  onSidebarToggleClick: () => void;
  userProfile: UserProfile;
  className?: string;
}

export const Header = ({
  hotelId,
  pageDisplayName,
  hotelName,
  onSidebarToggleClick,
  userProfile,
  className,
}: HeaderProps) => {
  const isAdmin = userProfile.role === "ADMIN";

  return (
    <div
      className={cn(
        "absolute top-0 flex w-full flex-row items-center justify-between gap-3 p-4",
        className,
      )}
    >
      <div className="flex flex-row items-center gap-3">
        <Button variant="ghost" type="button" onClick={onSidebarToggleClick}>
          <LayoutDashboard className="size-7 text-brand opacity-70" />
        </Button>
        <ChevronRight className="size-4 opacity-70" />
        <Breadcrumbs part1={hotelName} part2={pageDisplayName} />
      </div>
      <SelectHotelHeader value={hotelId} isAdmin={isAdmin} />
    </div>
  );
};
