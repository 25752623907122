import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/dialog";

import { zodResolver } from "@hookform/resolvers/zod";
import { Send } from "lucide-react";
import type { ReactNode } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { useConfigContext } from "~/components/common/config/config-context";
import { Button } from "~/components/common/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Textarea } from "~/components/ui/textarea";
import { useToast } from "~/components/ui/use-toast";
import { api } from "~/utils/api";

const formSchema = z.object({
  feedback: z.string(),
});

interface FeedbackDialogProps {
  DialogTriggerElement: ReactNode;
}

export const FeedbackDialog = ({
  DialogTriggerElement,
}: FeedbackDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { hotelId } = useConfigContext();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      feedback: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const createFeedback = api.misc.feedback.useMutation();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    try {
      await createFeedback.mutateAsync({
        ...values,
        hotelId,
      });
      toast({
        title: "Feedback was sent, we will contact you shortly",
      });
      form.reset();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className="focus-visible:outline-none">
        {DialogTriggerElement}
      </DialogTrigger>
      <DialogContent className="border-grey4">
        <DialogTitle>Feedback</DialogTitle>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="feedback"
              render={({ field }) => (
                <FormItem className="max-w-96">
                  <FormLabel>
                    We're always looking to improve! Share your thoughts on our
                    analytics dashboard. Every bit of feedback helps us serve
                    you better.
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} className="min-h-52" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              loading={isLoading}
              variant="primary"
              className="flex flex-wrap items-center gap-2"
            >
              <Send size={16} /> Submit
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
