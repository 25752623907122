import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

// console.log(`muly:ENV`, {
//   env: process.env,
// });

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    DATABASE_URL: z.string().url(),
    NODE_ENV: z.enum(["development", "test", "production"]),
    SUPABASE_SECRET_KEY: z.string(),
    ADMIN_SECRET: z.string(),
    UI_URL: z.string().url(),
    SENDGRID_API_KEY: z.string(),
    CLOUDBEDS_CLIENT_ID: z.string(),
    CLOUDBEDS_CLIENT_SECRET: z.string(),
    CLOUDBEDS_REDIRECT_URL: z.string(),
    MEWS_CLIENT_SECRET: z.string(),
    SLACK_NOTIFICATIONS_URL: z.string().url(),
    SLACK_NOTIFICATIONS_URL_USERS: z.string().url(),
    TRIGGER_API_KEY: z.string(),
    TRIGGER_API_URL: z.string().url(),
    STRIPE_SECRET_KEY: z.string(),
    STRIPE_WEBHOOK_SECRET: z.string(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string(),
    NEXT_PUBLIC_SUPABASE_URL: z.string().url(),
    NEXT_PUBLIC_ENVIRONMENT: z.string(),
    NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY: z.string(),
    // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    DATABASE_URL: process.env.DATABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    SUPABASE_SECRET_KEY: process.env.SUPABASE_SECRET_KEY,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    ADMIN_SECRET: process.env.ADMIN_SECRET,
    UI_URL: process.env.UI_URL,
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    CLOUDBEDS_CLIENT_ID: process.env.CLOUDBEDS_CLIENT_ID,
    CLOUDBEDS_CLIENT_SECRET: process.env.CLOUDBEDS_CLIENT_SECRET,
    CLOUDBEDS_REDIRECT_URL: process.env.CLOUDBEDS_REDIRECT_URL,
    MEWS_CLIENT_SECRET: process.env.MEWS_CLIENT_SECRET,
    SLACK_NOTIFICATIONS_URL: process.env.SLACK_NOTIFICATIONS_URL,
    SLACK_NOTIFICATIONS_URL_USERS: process.env.SLACK_NOTIFICATIONS_URL_USERS,
    TRIGGER_API_KEY: process.env.TRIGGER_API_KEY,
    TRIGGER_API_URL: process.env.TRIGGER_API_URL,
    NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY:
      process.env.NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
