import { Check, ChevronsUpDown } from "lucide-react";
import { useRouter } from "next/router";
import { useState } from "react";
import { Button } from "~/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import { cn } from "~/lib/utils";
import { api } from "~/utils/api";

interface Props {
  value: string | undefined;
  isAdmin: boolean;
}

export const SelectHotelHeader = ({ value, isAdmin }: Props) => {
  const router = useRouter();
  const { data, isLoading } = api.reports.getHotelList.useQuery(undefined, {
    placeholderData: (prev) => prev ?? [],
  });

  const [selectedHotel, setSelectedHotel] = useState<string | undefined>(() => {
    if (typeof window !== "undefined") {
      return window.localStorage.getItem("hotelId") || value;
    }
    return value;
  });

  const [searchValue, setSearchValue] = useState("");

  const hotelOptions = (data ?? [])
    .filter(({ isGroup }) => !isGroup)
    .sort((a, b) => a.name.localeCompare(b.name));

  const [open, setOpen] = useState(false);

  const onValueChange = async (hotelId: string) => {
    setSelectedHotel(hotelId);
    const url = router.pathname.replace("[hotelId]", hotelId);
    console.log("onValueChange", {
      url,
      hotelId,
      p: router.pathname,
      router,
    });

    window.sessionStorage.setItem("hotelId", hotelId);
    window.localStorage.setItem("hotelId", hotelId);

    await router.push(url);
    console.log("After navigation", {
      url,
      hotelId,
      p: router.pathname,
      router,
    });
  };

  if (!isLoading && !data?.length) {
    return <div>No hotels, please contact support to allow access</div>;
  }

  if (isAdmin || hotelOptions.length > 1) {
    return (
      <div className="min-w-72">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="min-w-72 justify-end border-none shadow-none hover:bg-white"
            >
              {selectedHotel
                ? hotelOptions.find((hotel) => hotel.id === selectedHotel)?.name
                : "Select a hotel"}
              <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="min-w-72 border-grey3 p-0">
            <Command className="border-grey3">
              <div className="[&>div]:border-grey3">
                <CommandInput
                  placeholder="Search hotel..."
                  value={searchValue}
                  onValueChange={(value) => {
                    const valueWithoutTrimStart = value.trimStart();
                    if (
                      hotelOptions.find((hotel) =>
                        hotel.name.toLowerCase().includes(value.toLowerCase()),
                      )
                    ) {
                      setSearchValue(valueWithoutTrimStart);
                    } else {
                      setSearchValue(valueWithoutTrimStart.replace(/\s+$/, ""));
                    }
                  }}
                />
              </div>
              <CommandList>
                <CommandEmpty>No hotels found.</CommandEmpty>
                <CommandGroup>
                  {hotelOptions.map(({ id, name }) => (
                    <CommandItem
                      className="cursor-pointer select-all data-[disabled]:pointer-events-auto data-[disabled]:opacity-100"
                      key={id}
                      value={name}
                      onSelect={(currentValue) => {
                        setOpen(false);
                        const id = hotelOptions.find(
                          (hotel) => hotel.name === currentValue,
                        )?.id;
                        return onValueChange(id || selectedHotel || "");
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 size-4",
                          selectedHotel === id ? "opacity-100" : "opacity-0",
                        )}
                      />
                      {name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    );
  } else {
    return null;
  }
};
